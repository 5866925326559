<template>
  <div class="pages">
    <div class="message-title">
      <div class="title">
        <span class="line"></span>
        <span>消息管理</span>
      </div>
    </div>
    <div class="message-tab">
      <div class="tab active">
        <span>系统通知</span>
      </div>
      <!--      <div class="tab">-->
      <!--        <span>楼言楼语通知</span>-->
      <!--      </div>-->
    </div>
    <div class="message-list">
      <div class="list">
        <div class="type">
          <span>发件人</span>
        </div>
        <div class="title">
          <span>消息内容</span>
        </div>
        <div class="date">
          <span>时间</span>
        </div>
      </div>
      <div class="list" v-for="(item,index) in list" :key="index" @click="handleMessageItemClick(index)">
        <div class="type">
          <span>系统</span>
        </div>
        <div class="title">
          <span>{{ item.content }}!</span>
        </div>
        <div class="date">
          <span>{{ item.create_time }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageList',
  data() {
    return {
      list: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      let res = await this.http({
        url: '/api/my/message',
        method: 'GET',
      })
      this.list = res.data
    },
    handleMessageItemClick(index) {
      this.$router.push({
        name: 'MessageInfo',
        params: {
          messageData: JSON.stringify(this.list[index])
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "MessageList.scss";
</style>
